import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const buildVuetify = (props) => {
    return new Vuetify(props)
}

export {
    buildVuetify
}
