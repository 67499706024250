<template>
    <v-app>
        <v-app-bar app>
            <a href="https://myphoneyaku.com">
                <v-img max-height="50px" alt="MyPhone訳ロゴ" src="/static/assets/logo_sm.png" contain position="left center"></v-img>
            </a>
        </v-app-bar>
        <v-content>
            <slot></slot>
        </v-content>
    </v-app>
</template>

<script>
    export default {
        name: "Base"
    }
</script>
